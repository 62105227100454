export const coverflow_data = [
  {
    img: "/images/games/mole.jpg",
    title: "Mole Mayhem",
    authorImage: "/images/stores/itch-io.svg",
    authorName: "itch.io",
    id: "mole-mayhem",
  },
  {
    img: "/images/games/basketball.jpg",
    title: "Backyard Basketball",
    authorImage: "/images/stores/itch-io.svg",
    authorName: "itch.io",
    id: "backyard-basketball",
  },
  {
    img: "/images/games/drums.png",
    title: "Sequencing Drums",
    authorImage: "/images/stores/itch-io.svg",
    authorName: "itch.io",
    id: "sequencing-drums",
  },
  {
    img: "/images/games/above_clouds.png",
    title: "Above Clouds",
    authorImage: "/images/stores/itch-io.svg",
    authorName: "itch.io",
    id: "above-clouds",
  },
  {
    img: "/images/games/pixel_canvas.jpg",
    title: "Pixel Canvas",
    authorImage: "/images/stores/itch-io.svg",
    authorName: "itch.io",
    id: "canvas",
  },
];

export const hero_5_data = [
  {
    img: "/images/products/item_16_2lg.jpg",
    title: "Etherium NFT Launching Lab",
    authorImage: "/images/avatars/avatar_18_rounded.gif",
    authorName: "051_Hart",
    id: "0Etherium NFT Launching Lab",
  },
  {
    img: "/images/products/item_12_2lg.jpg",
    title: "Oceania \\\\ OVERSEER 017",
    authorImage: "/images/avatars/avatar_22_rounded.jpg",
    authorName: "MadeByM1KE",
    id: "1Oceania \\\\ OVERSEER 017",

    subItem: [
      {
        img: "/images/products/item_13_lg.jpg",
        title: "Light Bars",
        authorImage: "/images/avatars/avatar_17_rounded.jpg",
        authorName: "Wow Frens",
        id: "2Light Bars",
      },
    ],
  },
];
