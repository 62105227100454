import React from "react";
import {
  Auctions_categories,
  Feature_collections,
  NewseLatter,
  Partners,
  Top_collection,
} from "../../components/component";
import Meta from "../../components/Meta";
import Hero_4 from "../../components/hero/hero_4";
import CoverflowCarousel from "../../components/carousel/coverflowCarousel";
import Hero_4_simple from "../../components/hero/hero_4_simple";

const Home_4_Simple = () => {
  return (
    <>
      <Meta title="Harium" />
      <Hero_4_simple />
      <CoverflowCarousel />
    </>
  );
};

export default Home_4_Simple;
